import React from 'react'
import { Container } from 'reactstrap'
import CareerRequirements from '../components/CareerRequiremetns/CareerRequirements'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const JobDescriptionFullStackUS = () => {
    return (
        <div>
            <Helmet title='DNNae | NLP JD' />
            <Layout
                containerStyle={{
                    position: 'relative'

                }}
                buttonStatus={false}
                selectedState=''
            >

                <Container
                    
                    style={{
                    maxWidth: '1920px'
                    }}
                >
                <CareerRequirements
                
                    
                    jobTitle='NLP Engineer'
                    jobTypeAndPlace='Contract, San Francisco'
                    weAreLooking='We are looking for a Natural Language Processing Engineer to help us improve and further add to the NLP aspect of our product. To succeed in this role, you should possess outstanding skills in statistical analysis, machine learning methods and text representation techniques. Your ultimate goal is to develop efficient and intelligent NLP features for the product.'
                    responsibilitiesArray={[
                        // 'Study and transform data science prototypes',
                        'Design and implement NLP pipelines and  applications',
                        'Select, annotate and curate datasets for model training and testing',
                        'Use effective text representations to transform natural language into useful features',
                        'Find and implement the right algorithms and tools for NLP tasks',
                        'Develop NLP systems according to requirements',
                        'Train the developed model and run evaluation experiments',
                        'Perform statistical analysis of results and refine models',
                        'Extend ML libraries and frameworks to apply in NLP tasks',
                        'Remain updated in the rapidly changing field of machine learning',
                    ]}
                    
                    whatYouNeedArray={[
                        'Proven experience as an NLP Engineer or similar role',
                        'Theoretical and hands-on experience of working with deep learning based NLP models such as BERT, GPT, XLNet, Word2Vec, Transformers, etc. Should be able to code/build a deep learning architecture from scratch or modify it according to requirements in Tensorflow or PyTorch.',
                        'Should be able to set up a CUDA environment and use GPU for training and testing.',
                        'Theoretical and hands on experience of working with traditional ML algorithms such as Naïve Bayes, Decision Trees, Random Forests etc.',
                        'Theoretical and hands on experience of working with various NLP concepts including, vector space model, cosine similarity, jaccard coefficient, stemming, lemmatization, inverted indexes, language modelling, word grams, character grams, etc.',
                        'Knowledge of Python',
                        'Knowledge of databases, and basic proficiency with SQL is also required.',
                        'Should be able to deploy the NLP model as a service or an API.',
                        'Be proficient in python and its packages such as sklearn, pandas, numpy, etc.',
                        'Have professional or practical experience of working on few of the following application areas: text classification, named entity recognition, topic identification, machine translation, transliteration, question answering systems, text summarization, keyword extraction, predictive text, smart assistants, etc.',
                        'An analytical mind with problem-solving abilities',
                        'Degree in Computer Science, Mathematics, Computational Linguistics or similar field',
                    ]}
                >

                </CareerRequirements>

                </Container>
            </Layout>
        </div>
    )
}

export default JobDescriptionFullStackUS
