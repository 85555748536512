import React from 'react'
import LandingPageHeadingAndTagline from '../LandingPageHeadingAndTagline/LandingPageHeadingAndTagline'
import Layout from '../layout/layout'
import NothingOfYourInterest from '../NothingOfYourInterest/NothingOfYourInterest'

import '../GlobalStyle.scss'
import FunctionalityCard from '../FunctionalityCard/FunctionalityCard'

import './CareerRequirements.scss'


const CareerRequirements = ({
    jobTitle,
    jobTypeAndPlace,
    weAreLooking,
    responsibilitiesArray,
    whatYouNeedArray,
    containerStyle,
}) => {
    return (
        <div 
            style={{
            // background:
            // `radial-gradient(circle at 29% 40%, rgba(139, 181, 255, 0.37), rgba(225, 251, 255, 0) 62%), 
            // radial-gradient(circle at 79% 45%, rgba(255, 144, 233, 0.4), rgba(225, 251, 255, 0) 70%), 
            // radial-gradient(circle at 42% 47%, rgba(0, 148, 255, 0.25), rgba(225, 251, 255, 0) 56%)`,
            // ...containerStyle
        }}
    
        
            className='careerRequirements'
            
            >
            <div className='careerRequirementsJobTitle'>

                    <h1>{jobTitle}</h1>
                    <h3>{jobTypeAndPlace}</h3>
            </div>

            <div className='careerRequirementsWeAreLooking'>
                    <p>{weAreLooking}</p>

            </div>
            <div
                className='careerRequirementsHeadingSpacer'
            >
                
                <h4 
                    style={{
                        margin:'32px 0px'
                    }}
                    >Responsibilities</h4>
                    <ul>
                    {
                        responsibilitiesArray.map((res , idx) => <li 
   
                        key={idx}>{res}</li>)
                    }
                    </ul>
                <h4 
                    style={{
                        margin:'32px 0px'
                    }}                    
                    >What you need</h4>
                    <ul>
                    {
                        whatYouNeedArray.map((res , idx) => <li 
                     
                        key={idx}>{res}</li>)                    
                    }

                    </ul>
            </div>
            
            <div 
                
                className='careerRequirementFuncCardControl'
            >
                <FunctionalityCard
                    headingStyle={{color: '#297AF7'}}
                    containerStyle={{background: '#F7FAFF'}}
                    innerContainerStyle={{textAlign: 'center'}}
                    heading='Apply Now!'
                    text="To apply for this job, please send us your resume at <a href='mailto:admin@dnnae.com'>admin@dnnae.com</a>"
                
                />
            </div>
        </div>
    )
}

export default CareerRequirements
