import React from 'react'
import { Button } from 'reactstrap'

import '../GlobalStyle.scss'
import './LandingPageHeadingAndTagline.scss'


const LandingPageHeadingAndTagline = ({

    heading,
    heading2,
    headingColorStyle,
    tagline,
    conatinerStyle,
    descriptionStyle,
    pngSrc,
    pngSrcClassName,
    containerClassName,
    buttonName,
    buttonHref,
    buttonContainerStyle={width: 'minContent'}

}) => {
    return (
        <div 
        
            style={{...conatinerStyle}} 
            className={containerClassName}>
            
            <div             
                className='landingPageHeadingAndTagline'>                                       {/* style={pngSrcClassName !== 'landingPageHeadingAndTagline_Image_Index' ? {    padding: '180px 0px 63px 7%'} : {}} */}
                <div className='landingPageHeadingAndTaglineHeadingAndPara'>
                    {/* <span
                        style={{
                            // display:'flex',
                            
                            
                        }}
                    > */}
                        <h1>
                            {heading}
                        </h1>
                        {/* <h1
                            style={{
                                margin: '0px 12px',
                                ...headingColorStyle,
                                display: 'inline'
                            }}
                        >
                            {heading2}
                        </h1> */}
                    {/* </span> */}
                    <p>
                        {tagline}
                    </p>
                </div>
                {  buttonName ? <a 
                                    style={{
                                        width: 'min-content'
                                    }}
                                    href={ buttonHref ? buttonHref : '#contactSalesPanel'}
                                    className='headingAndTaglineButtonControl'
                                >
                        <Button    
                            color='primary'
                            style={{
                                width: 'max-content'
                            }}
                        >
                        {buttonName}
                    </Button>
                </a>: 

                <a 
                    href='#heroDropperFunctionality'
                >
                    <svg   
                        className='landingPageDropperControl'
                        width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4">
                        <circle cx="24" cy="24" r="23" stroke='#FFFFFF' stroke-width="2"/>
                        <path d="M16 20L24 28L32 20" stroke='#FFFFFF' stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g> 
                    </svg>
                </a>

                }
            </div>
            {pngSrc && <img
                src={pngSrc}
                className={pngSrcClassName}
            />}
            
        </div>
    )
}

export default LandingPageHeadingAndTagline
