import React from "react";

import "./FooterContainer.scss";

import DNNae from "../Images/DNNae.svg";
// import DNNaeText from "../Images/DNNaeText.svg";
import { Link } from "gatsby";

const FooterContainer = ({ footerStyle, selectedState }) => {
  return (
    <div style={{ ...footerStyle }} className="footerContainer">
      <Link
        to="/"
        style={{
          minWidth: "max-content",
        }}
      >
        <span className="logoContainerLogoAndName">
          <img
            src={DNNae}
            style={{
              marginRight: "8px",
            }}
          />
          {/* <img src={DNNaeText}/> */}
        </span>
      </Link>
      <span className="footerLinkProperty">
        <span className="footerLinkProperty_ContainerOne">
          <Link
            to="/"
            style={
              selectedState === "index"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Recruiting Assistant
          </Link>
          <Link
            to="/pricing"
            style={
              selectedState === "pricing"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Pricing
          </Link>
          <Link
            to="/support"
            style={
              selectedState === "support"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Support
          </Link>
          <Link
            to="/careers"
            style={
              selectedState === "careers"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Careers
          </Link>
        </span>
        <span className="footerLinkProperty_ContainerTwo">
          <Link
            to="/tos"
            style={
              selectedState === "tos"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Terms
          </Link>
          <Link
            to="/privacy-policy"
            style={
              selectedState === "privacyPolicy"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Privacy Policy
          </Link>
          <Link
            to="/disclosure"
            style={
              selectedState === "disclosure"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
            className="globalLinks"
          >
            Disclosure
          </Link>
        </span>
      </span>
    </div>
  );
};

export default FooterContainer;
