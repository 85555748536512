import React from 'react'

import './FunctionalityCard.scss'
import '../GlobalStyle.scss'

const FunctionalityCard = ({
    pngSrc,
    heading='',
    text='',
    containerStyle,
    innerContainerStyle,
    headingStyle,

}) => {
    return (
        <div 
            style={{...containerStyle}}
            className='functionalityCard'
        >

            { pngSrc && <img
                src={pngSrc}
            />}

            <div
                style={{...innerContainerStyle}}
            >
                <h2 
                    style={{...headingStyle}}
                >
                    {heading}
                </h2>
                <p 
                    dangerouslySetInnerHTML={{__html: text}}
                />
            </div>
        </div>
    )
}

export default FunctionalityCard
