import * as React from 'react'
import { Link } from 'gatsby'

import NavigationBar from '../NavigationBar/WebsiteNavigationBar'
import Footer from '../Footer/FooterContainer'

import './layoutContainer.scss'
import { Container } from 'reactstrap'

const Layout = ({ 
    
    pageTitle,
    children, 
    containerStyle={},
    selectedState='',
    buttonStatus= true,
    contactSalesLink='',
  
  }) => {
  return (
    <div  
    
      style={{...containerStyle}}
      className='layoutContainer'>

        <div
          style={{        
            position: 'sticky',
            top: '0',
            height: '94px',
            // width: '100vw',
            boxShadow: '0px 2px 3px rgb(0 0 0 / 7%)',
            zIndex: '4000'
          }}
        />

        <Container
          style={{
            maxWidth: '1920px',
            zIndex: '9000',
            marginTop: '-94px',
          }}
        >
          <NavigationBar
            buttonStatus={buttonStatus} 
            selectedState={selectedState}
            burgerContainerStyle={{
              background: 'rgb(247, 250, 255)',
              position: 'relative'

            }}
            contactSalesLink={contactSalesLink}
          />
              {children}
          
        </Container>
        <div
          className='footerBackground'
        ></div>
        <Container
          style={{
            maxWidth: '1920px',
          }}
        >
          <Footer
              selectedState={selectedState}
              // footerStyle={ {
              //   background: 'rgba(242, 166, 104, 0.08)'
              // }}
            
            />
          
        </Container>
    </div>
  )
}
export default Layout