import React from 'react'

import './NothingOfYourInterest.scss'
import '../GlobalStyle.scss'
import { Button } from 'reactstrap'
import { Link } from 'gatsby'


const NothingOfYourInterest = ({
    thisId='',
    containerStyle={},
    heading='',
    title='',
    subTitle='',
    subHeading,
    description='',
    pngSrc,
    subTitle_Style={},
    description_Style={},
    detail_Style={},
    buttonLabel='',
    mainLinkTo,

}) => {


    return (
        <div
            style={{...containerStyle}}
            className='nothingOfYourInterest'
        >
            {pngSrc && 
            <div
                className='nothingOfYourInterest_Png'
            >
                <img
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    src={pngSrc}

                />
            </div>
            }

            <div 
                style={{...detail_Style}}
                className='nothingOfYourInterest_Text'
                
            >
                {heading && <h3 className='globalHeadingStyle headingSpacer'>{heading}</h3>}
                {title && <h1 className='globalTitleStyle titleSpacer'>{title}</h1>}
                {subHeading && <h4>{subHeading}</h4>}
                {subTitle && <h2
                                style={{...subTitle_Style}}
                                    >{subTitle}</h2>}
                {description && <p style={{...description_Style}} dangerouslySetInnerHTML={{__html: description}} />}
                {buttonLabel && 
                <Link
                    style={{
                        width: '112px'
                    }}
                    to={mainLinkTo}
                ><Button>{buttonLabel}</Button></Link>}
            </div>
            
        </div>
    )
}


export default NothingOfYourInterest
